
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { Getter } from "@/store/helper";
import {
  ComplementedSubmission,
  SubmissionStatus
} from "@/entities/submission";
import { MessageUserType } from "@/entities/message";
import { MessageToType } from "@/entities/message_reservation";
import {
  getSubmissionById,
  complementSubmissions,
  putSubmission
} from "@/api/submission";
import { setNotificationForSubmissionChecked } from "@/api/notification";
import { getSchoolDetail } from "@/api/school";
import { createMessageReservation } from "@/api/message_reservation";
import { sendTextMessage, sendButtonTemplateMessage } from "@/api/message";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MSMessagePanel from "@/components/student/MSMessagePanel.vue";
import dayjs from "dayjs";

@Options({
  components: {
    MIcon,
    MButton,
    MSMessagePanel
  }
})
export default class SubmissionPrint extends Vue {
  dataMaps: ComplementedSubmission[] = [];
  useMessagePanel = false;
  imageUrlsRequiringAltDisplay: string[] = [];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;

  get icon() {
    return require("@/assets/student-icon.svg");
  }

  toTimeString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)} ${convertToTimeFromUnixtime(
      unix
    )}`;
  }

  isLongComment(comment: string): boolean {
    return comment.length + (comment.split("\n").length - 1) * 100 > 300;
  }

  print() {
    print();
  }

  async setSubmissionStatus(
    dataMap: ComplementedSubmission,
    status: SubmissionStatus
  ) {
    // ビルドを通すため例外を除外
    if (
      !dataMap.studentRef ||
      !dataMap.submissionMasterData ||
      (this.isTutorOfSchool && !store.state.tutor) ||
      (this.isAdmin && !store.state.admin) ||
      !store.state.role
    ) {
      return;
    }
    store.commit("START_LOADING", "処理中");
    const now = dayjs();
    try {
      await putSubmission(dataMap.ref, {
        checkedAt: now.unix(),
        status
      });
    } catch (e) {
      alert(`提出物の更新中にエラーが発生しました。`);
      store.commit("END_LOADING");
      return;
    }

    try {
      // 塾名の取得
      const [school] = await getSchoolDetail(
        false,
        dataMap.submissionMasterRef?.parent?.parent?.id ?? ""
      );
      if (!school) throw new Error("school not found");
      const schoolName = school.data.name;
      // 差出人表記の取得
      let fromName = "";
      if (this.isTutorOfSchool) {
        fromName = store.state.tutor?.main.data.name ?? "";
      } else if (this.isAdmin) {
        fromName = store.state.admin?.name ?? "";
      } else {
        throw new Error("user not found");
      }
      const messageFrom = {
        type: "tutor" as MessageUserType,
        userId: store.state.role.ref.id ?? "",
        name: fromName
      };

      let statusLabel = "";
      if (status === "fulfilled") {
        statusLabel = "確認済";
      } else if (status === "rejected_unsubmitted") {
        statusLabel = "再提出";
      }

      let messageText = "";
      if (status === "fulfilled") {
        messageText = `🙆‍♂️「${dataMap.submissionMasterData.name}」を確認しました。おつかれさま😄`;
      } else if (status === "rejected_unsubmitted") {
        messageText = `「${
          dataMap.submissionMasterData.name.length <= 23
            ? dataMap.submissionMasterData.name
            : `${dataMap.submissionMasterData.name.slice(0, 23)}..`
        }」の内容を確認して、もう一度提出してください🧐`;
      }

      if (now.hour() >= 7 && now.hour() <= 21) {
        // 7:00 ~ 21:59 の間は即座に生徒にメッセージを送信する
        if (status === "fulfilled") {
          await sendTextMessage(
            dataMap.studentRef,
            schoolName,
            messageFrom,
            messageText
          );
        } else if (status === "rejected_unsubmitted") {
          const nameString = `${schoolName} ${messageFrom.name}先生:`;
          await sendButtonTemplateMessage({
            data: {
              schoolDocId: school.ref.id,
              classroomDocId: dataMap.studentRef.parent.parent?.id ?? "",
              studentDocId: dataMap.studentRef.id,
              name: nameString,
              from: messageFrom,
              messageText,
              templateMessage: {
                altText: `${nameString} ${messageText}`,
                buttonLabel: "再提出する",
                buttonUriPath: `/submission/${dataMap.ref.id}/`,
                title: nameString
              }
            }
          });
        }

        alert(`提出物を${statusLabel}にし、生徒にメッセージを送信しました。`);
      } else {
        // 夜間早朝は次の 7:00 に送信されるメッセージを予約する
        const messageTo = {
          type: "students" as MessageToType,
          students: [dataMap.studentRef]
        };
        const reservationTime = dayjs()
          .add(now.hour() > 21 ? 1 : 0, "day")
          .hour(7)
          .minute(0)
          .second(0)
          .unix();
        if (status === "fulfilled") {
          await createMessageReservation(
            messageFrom,
            messageTo,
            "text",
            messageText,
            reservationTime,
            school.ref.id,
            schoolName
          );
        } else if (status === "rejected_unsubmitted") {
          const nameString = `${schoolName} ${messageFrom.name}先生:`;
          await sendButtonTemplateMessage({
            data: {
              schoolDocId: school.ref.id,
              classroomDocId: dataMap.studentRef.parent.parent?.id ?? "",
              studentDocId: dataMap.studentRef.id,
              name: nameString,
              from: messageFrom,
              messageText,
              templateMessage: {
                altText: `${nameString} ${messageText}`,
                buttonLabel: "再提出する",
                buttonUriPath: `/submission/${dataMap.ref.id}/`,
                title: nameString
              }
            },
            option: {
              useReservation: true,
              reservationTime
            }
          });
        }

        alert(
          `提出物を${statusLabel}にしました。生徒へのメッセージは次の朝 7 時に送信されます。`
        );
      }
      // 対応する通知メッセージを既読にする
      if (status === "fulfilled") {
        await setNotificationForSubmissionChecked(dataMap.ref);
      }

      // 今回の提出物のステータスを更新
      this.dataMaps = this.dataMaps.map(_dataMap => {
        if (_dataMap.ref.id !== dataMap.ref.id) return _dataMap;
        _dataMap.data.checkedAt = now.unix();
        _dataMap.data.status = status;
        return _dataMap;
      });
    } catch (e) {
      alert(`メッセージ送信中にエラーが発生しました。`);
    } finally {
      store.commit("END_LOADING");
    }
  }

  setAltImage(url: string) {
    this.imageUrlsRequiringAltDisplay = [
      url,
      ...this.imageUrlsRequiringAltDisplay
    ];
  }

  async mounted() {
    const params = new URLSearchParams(location.search);
    const ids = params.get("id")?.split(",") ?? [];
    if (ids.length === 0) return;
    try {
      const submissions = await getSubmissionById(ids);
      this.dataMaps = await complementSubmissions(submissions);
      const title = this.dataMaps
        .map(d => d.submissionMasterData?.name)
        .filter(_ => _)
        .filter((n, i, self) => self.indexOf(n) === i)
        .join("|");
      // 通常提出物が複数あっても提出物マスターは同じなので、すべて重複する
      document.title = title;

      // 対応する生徒が１人だけの場合、メッセージパネルを初期化
      const studentRefs = this.dataMaps
        .map(d => d.studentRef)
        .filter((ref, index, self) => self.indexOf(ref) === index);
      this.useMessagePanel = studentRefs.length === 1;
      if (this.useMessagePanel) {
        store.dispatch("messagePanel/changeMessageStream", studentRefs[0]);
      }
    } catch (e) {
      alert(`提出物取得中にエラーが発生しました\n\n${e.message}`);
    }
  }
}
