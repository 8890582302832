import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    "stroke-width": _ctx.strokeWidth,
    class: _ctx.iconClass,
    viewBox: _ctx.viewBox
  }, [
    _createVNode("path", { d: _ctx.path }, null, 8, ["d"])
  ], 10, ["stroke-width", "viewBox"]))
}